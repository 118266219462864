import React, { useEffect } from "react";
import { useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";

function SeeCost(props) {
  const navigate = useNavigate();

  function nav(params) {
    navigate("/");
  }
  function nav2(params) {
    navigate("/savdo-xomashyolar");
  }

  const [soldOut, setSoldOut] = useState({
    all_price_uzs: 0,
    all_price_usd: 0,
    data: [
      {
        id: 0,
        product_id: 0,
        price: 0,
        price_uzs: 0,
        price_usd: 0,
        nbu_id: 0,
        count: 0,
        created_at: "",
        updated_at: "",
        nbu: [
          {
            id: 0,
            code: "0",
            nbu_cell_price: 0,
            created_at: "0",
            updated_at: "0",
            deleted_at: null,
          },
        ],
        products: [
          {
            id: 0,
            caterogy_id: 0,
            name: "",
            price: 0,
            count: 0,
            type_id: 0,
            cyrrency: 0,
            is_active: 0,
            nbu_id: 0,
            type: 0,
            created_at: "",
            updated_at: "",
          },
        ],
      },
    ],
  });

  useEffect(() => {
    const url = new URL(
      "https://icecream.dadabayev.uz/api/products/getPriceLog"
    );

    const headers = {
      "Content-Type": "application/json",
      Accept: "application/json",
    };

    fetch(url, {
      method: "GET",
      headers,
    })
      .then((response) => response.json())
      .then((res) => setSoldOut(res.result));
  }, []);

  const [urlofpage] = React.useState(window.location.pathname);
  let i = 1;
  //
  //
  //
  //
  //
  //
  //
  //
  //
  //
  //
  //
  const base_url = require("../API.json");
  //
  //
  //
  //
  //

  const [data] = useState({});

  //
  //
  //
  //
  //
  //
  //
  //

  // function Send(params) {
  //   params.preventDefault();
  // }
  //
  //
  //
  //

  //
  //
  //

  const [from_date, setfrom_date] = useState("");
  const [to_date, setto_date] = useState("");

  const handleSubmit = async (e) => {
    e.preventDefault();

    let url = base_url.url + "/api/products/getPriceLog";

    if (from_date) {
      url += `?from_date=${from_date}`;
    }
    if (to_date) {
      url += `?to_date=${to_date}`;
    }

    function replaceQuestionMarks(url) {
      const parts = url.split("?");

      if (parts.length <= 1) {
        return url; // No "?" other than the first one
      }

      const firstPart = parts.shift();
      const restOfUrl = parts.join("?");
      const modifiedRest = restOfUrl.replace(/\?/g, "&");

      const modifiedUrl = firstPart + "?" + modifiedRest;
      return modifiedUrl;
    }

    const modifiedUrl = replaceQuestionMarks(url);

    try {
      const response = await fetch(modifiedUrl);
      const data = await response.json();
      setSoldOut(data.result);
    } catch (error) {
      console.error("Error fetching products:", error);
    }
  };

  const [searchTerm, setSearchTerm] = useState("");

  const filteredDataa = soldOut.data.filter((item) =>
    item.products
      .map((product) => product.name.toLowerCase())
      .join(" ")
      .includes(searchTerm.toLowerCase())
  );

  return (
    <div className="umumiyfoyda">
      <div className="name_back">
        <div>
          <div>
            <p className="page_title">Narxlarning o'zgarishi</p>
          </div>
          <p>
            <span onClick={() => nav()}>Bosh sahifa </span>
            <span onClick={() => nav2()}>/ombor </span>{" "}
            <span className="cur_page">{urlofpage}</span>
          </p>
        </div>
        <NavLink to="/ombor">
          <button>Orqaga qaytish</button>
        </NavLink>
      </div>
      <div className="sidebar_table">
        <div className="sidebar">
          <h1>Filter</h1>
          <form onSubmit={handleSubmit} className="Add_p" action="">
            <div>
              <input
                type="date"
                value={data.from_date}
                onChange={(e) => setfrom_date(e.target.value)}
                placeholder="Boshlangan sana"
              />
            </div>
            <div>
              <input
                type="date"
                placeholder="Tugagan sana"
                value={data.to_date}
                onChange={(e) => setto_date(e.target.value)}
              />
            </div>
            <div className="res_but">
              <button type="submit">Jo'natish</button>
            </div>
          </form>
        </div>

        <div style={{ width: "100%" }}>
          <input
            type="text"
            className="search_input"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            placeholder="qidiruv"
          />
          <table border={1} className="ttable">
            <thead>
              <tr>
                <th>№</th>
                <th>Nomi</th>
                <th>Qiymati</th>
                <th>Narxi</th>
                <th>USD ({soldOut.all_price_usd.toLocaleString("en-US")})</th>
                <th>UZS ({soldOut.all_price_uzs.toLocaleString("en-US")})</th>
                <th>Yaratilgan sanasi</th>
                <th>Yaratilgan vaqti</th>
              </tr>
            </thead>
            <tbody>
              {filteredDataa.map((item) => (
                <tr key={item.id}>
                  <td>{i++}</td>
                  {/* {item.products.map((val) => (
                  <>
                    <td>{val.name}</td>
                    <td>{val.price}</td>
                    <td>{val.settings.map((val) => val.name)}</td>
                    <td>{item.value}</td>
                    <td>{val.cyrrency === 0 ? "UZS" : "USD"}</td>
                  </>
                ))} */}
                  {/* <td>
                  {item.products.map((val) =>
                    val.nbu.map((val) => val.nbu_cell_price)
                  )}
                </td> */}
                  <td>{item.products.map((item) => item.name)}</td>
                  <td>{item.count.toLocaleString("en-US")}</td>
                  <td>{item.price.toLocaleString("en-US")}</td>
                  <td>{item.price_usd.toLocaleString("en-US")} USD</td>
                  <td>{item.price_uzs.toLocaleString("en-US")} UZS</td>
                  {/* <td>
                  {item.products.map((val) =>
                    val.caterogies.map((val) => val.name)
                  )}
                </td> */}
                  <td>{item.created_at.slice(0, 10)}</td>
                  <td>{item.created_at.slice(11, 19)}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}

export default SeeCost;
