import React, { useEffect } from "react";
import { useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";

function Xomashyolar(props) {
  const navigate = useNavigate();

  function nav(params) {
    navigate("/");
  }
  function nav2(params) {
    navigate("/savdo-xomashyolar");
  }

  const [soldOut, setSoldOut] = useState({
    data: [
      {
        id: 0,
        material_id: 0,
        value: 0,
        type_id: 0,
        price_uzs: 0,
        price_usd: 0,
        count: 0,
        product_id: 0,
        created_at: "",
        updated_at: "",
      },
    ],
    used_uzs: 0,
    used_usd: 0,
    maked_products: 0,
    distinct_products: 0,
    distinct_materials: 0,
    distinct_types: 0,
    used_materials: {
      Dona: 0,
      Kg: 0,
    },
  });
  const base_url = require("../API.json");

  useEffect(() => {
    const url = new URL(base_url.url + "/api/expenses/get");

    const headers = {
      "Content-Type": "application/json",
      Accept: "application/json",
    };

    fetch(url, {
      method: "GET",
      headers,
    })
      .then((response) => response.json())
      .then((res) => setSoldOut(res.result));
  }, [base_url.url]);

  const [urlofpage] = React.useState(window.location.pathname);
  let i = 1;
  //
  //
  //
  //
  //
  //
  //
  //
  //
  //
  //
  //
  //
  //
  //
  //
  //

  const [data] = useState({});
  //
  //
  //
  //
  //
  //
  //
  //

  // function Send(params) {
  //   params.preventDefault();
  // }
  //
  //
  //
  //
  //
  //
  //
  //
  //
  //
  //
  //
  //
  //
  //
  //
  //
  //
  //
  //
  //
  //
  //
  //
  //
  //

  const [categoryId] = useState("");
  const [partnerId] = useState("");
  const [type_id] = useState("");
  const [material_id, setMaterial_id] = useState("");
  const [product_id, setproduct_id] = useState("");
  const [cyrrency] = useState();
  const [from_date, setfrom_date] = useState("");
  const [to_date, setto_date] = useState("");
  const handleSubmit = async (e) => {
    e.preventDefault();

    let url = base_url.url + "/api/expenses/get"; // Replace with your API endpoint

    if (categoryId) {
      url += `?caterogy_id=${categoryId}`;
    }
    if (partnerId) {
      url += `?partner_id=${partnerId}`;
    }
    if (material_id) {
      url += `?material_id=${material_id}`;
    }
    if (type_id) {
      url += `?type_id=${type_id}`;
    }
    if (product_id) {
      url += `?product_id=${product_id}`;
    }
    if (cyrrency) {
      url += `?cyrrency=${cyrrency === "true" ? 1 : 0}`;
    }
    if (from_date) {
      url += `?from_date=${from_date}`;
    }
    if (to_date) {
      url += `&to_date=${to_date}`;
    }
    function replaceQuestionMarks(url) {
      const parts = url.split("?");

      if (parts.length <= 1) {
        return url; // No "?" other than the first one
      }

      const firstPart = parts.shift();
      const restOfUrl = parts.join("?");
      const modifiedRest = restOfUrl.replace(/\?/g, "&");

      const modifiedUrl = firstPart + "?" + modifiedRest;
      return modifiedUrl;
    }

    const modifiedUrl = replaceQuestionMarks(url);

    try {
      const response = await fetch(modifiedUrl);
      const data = await response.json();
      setSoldOut(data.result);
    } catch (error) {
      console.error("Error fetching products:", error);
    }
  };

  useEffect(() => {
    const url = new URL(base_url.url + "/api/settings/get");

    const headers = {
      "Content-Type": "application/json",
      Accept: "application/json",
    };

    fetch(url, {
      method: "GET",
      headers,
    }).then((response) => response.json());
  }, [base_url.url]);
  const [productss, setProductss] = useState([
    {
      id: 0,
      caterogy_id: 0,
      name: "",
      price: 0,
      count: 0,
      type_id: 0,
      cyrrency: 0,
      is_active: 0,
      nbu_id: 0,
      type: 0,
      created_at: "",
      updated_at: "",
      caterogies: [],
    },
  ]);
  useEffect(() => {
    const url = new URL(base_url.url + "/api/products/get");

    const headers = {
      "Content-Type": "application/json",
      Accept: "application/json",
    };

    fetch(url, {
      method: "POST",
      headers,
    })
      .then((response) => response.json())
      .then((res) => setProductss(res.result));
  }, [base_url.url]);

  const [product2, setProduct2] = useState([
    {
      id: 0,
      caterogy_id: 0,
      name: "",
      price: 0,
      count: 0,
      type_id: 0,
      cyrrency: 0,
      is_active: 0,
      nbu_id: 0,
      type: 0,
      created_at: "",
      updated_at: "",
      caterogies: [],
    },
  ]);

  console.log(product2);

  useEffect(() => {
    const url = new URL("https://icecream.dadabayev.uz/api/products/get");

    const headers = {
      "Content-Type": "application/json",
      Accept: "application/json",
    };

    fetch(url, {
      method: "POST",
      headers,
    })
      .then((response) => response.json())
      .then((res) => setProduct2(res.result));
  }, []);

  return (
    <div className="umumiyfoyda">
      <div className="name_back">
        <div>
          <div>
            <p className="page_title">Xomashyolar</p>
            <p className="usd_uzs">
              <span className="page_title">{soldOut.used_usd} USD</span>{" "}
              <span className="page_title">{soldOut.used_uzs} UZS</span>
            </p>
          </div>
          <p>
            <span onClick={() => nav()}>Bosh sahifa </span>
            <span onClick={() => nav2()}>/sotilgan-maxsulotlar </span>{" "}
            <span className="cur_page">{urlofpage}</span>
          </p>
        </div>
        <NavLink to="/savdo-xomashyolar">
          <button>Orqaga qaytish</button>
        </NavLink>
      </div>
      <div className="sidebar_table">
        <div className="sidebar">
          <h1>Filter</h1>
          <form onSubmit={handleSubmit} className="Add_p" action="">
            <div>
              <select
                name="type"
                value={data.material_id}
                onChange={(e) => setMaterial_id(e.target.value)}
                id=""
              >
                <option value="">Xomashyoni tanlang</option>
                {productss
                  .filter((val) => val.type === 1)
                  .map((val) => (
                    <option value={val.id}>{val.name}</option>
                  ))}
              </select>
            </div>
            <div>
              <select
                name="type"
                value={data.product_id}
                onChange={(e) => setproduct_id(e.target.value)}
                id=""
              >
                <option value="">Maxsulotni tanlang</option>
                {productss
                  .filter((val) => val.type === 0)
                  .map((val) => (
                    <option value={val.id}>{val.name}</option>
                  ))}
              </select>
            </div>
            <div>
              <input
                type="text"
                value={data.from_date}
                onChange={(e) => setfrom_date(e.target.value)}
                placeholder="Boshlangan sana"
              />
            </div>
            <div>
              <input
                type="text"
                placeholder="Tugagan sana"
                value={data.to_date}
                onChange={(e) => setto_date(e.target.value)}
              />
            </div>
            <div className="res_but">
              <button type="submit">Jo'natish</button>
            </div>
          </form>
        </div>
        <table border={1} className="ttable">
          <thead>
            <tr>
              <th>№</th>
              <th>Xomashyo nomi</th>
              <th>Maxsulot nomi</th>
              <th>Miqdori</th>
              <th>Turi</th>
              <th>USD</th>
              <th>UZS</th>
              <th>Yaratilgan sana</th>
              <th>Yangilangan sana</th>
            </tr>
          </thead>
          <tbody>
            {soldOut.data
              .filter((item) => item.value > 0)
              .map((item) => (
                <tr>
                  <td>{i++}</td>
                  <td>
                    {product2
                      .filter((val) => val.id === item.material_id)
                      .map((val) => val.name)}
                  </td>
                  <td>
                    {product2
                      .filter((val) => val.id === item.product_id)
                      .map((val) => val.name)}
                  </td>
                  <td>{item.value}</td>
                  <td>{item.type_id === 1 ? "Kg" : "Dona"}</td>
                  <td>{item.price_usd}</td>
                  <td>{item.price_uzs}</td>
                  <td>{item.created_at.slice(0, 10)}</td>
                  <td>{item.updated_at.slice(0, 10)}</td>
                </tr>
              ))}
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default Xomashyolar;
